<template>
  <form @input="$emit('input', formattedLetterWords)">
    <input type="text" v-model="letterWords" placeholder="Ex: This is a good day / New sentence here / How are you" />
  </form>
</template>

<script>
// "letterWords": ["hello", "hi", "bye"]

export default {
  name: 'Type3a',
  data() {
    return {
      letterWords: '',
    };
  },
  computed: {
    formattedLetterWords() {
      let result = JSON.parse(JSON.stringify(this.letterWords))
      .replace(/[\,\/]/gi, ',').split(',')
      return result;
    },
  },
};
</script>
